import { ProductCategory } from '~/types/components/search';
import {
  categoryRootKidsId,
  categoryRootLifestyleId,
  categoryRootMenId,
  categoryRootWomenId,
} from '~/components/catalog/consts';

export const MIN_SEARCH_QUERY_LENGTH = 3;
export const SEARCH_INPUT_SEND_ANALYTICS_DELAY = 1000;
export const SEARCH_SIZE_PARAMS: { size: number } = { size: 10 };
export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    id: 'women', name: 'composables.useGender.female', url: 'zhenskoe', categoryId: categoryRootWomenId,
  },
  {
    id: 'men', name: 'composables.useGender.male', url: 'muzhskoe', categoryId: categoryRootMenId,
  },
  {
    id: 'kids', name: 'composables.useGender.child', url: 'detskoe', categoryId: categoryRootKidsId,
  },
  {
    id: 'lifestyle', name: 'composables.useGender.lifestyle', url: 'lifestyle', categoryId: categoryRootLifestyleId,
  },
];
